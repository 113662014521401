:root{
  --color-main: #003F46;
  --color-main-light: #00686E;
  --color-bg-table-label: #F1F6F7;
  --color-secondary-button: #00686E;
  --color-button: #00686E;
  --color-main-dark: #002A32;

  --color-phone-number: #003F46;
  --color-banner: #003F46;
  --color-underline-stepper: #E1FF00;
  --color-bg-banner: #F1F6F7;
  --color-bg-sub-page: #F1F6F7;

  --color-bg-loading-screen: #003F46;
  --color-loading-screen: #F1F6F7;

  --color-bg-coverage-level-selected: #FAFFDC;
  --color-bg-coverage-option-tall: rgba(255, 255, 255, 0.3);
  --color-bg-infodiv: #FAFFDC;
  --color-header-infodiv: #00686E;
  --color-content-infodiv: #002A32;
  --color-bg-choice-icon: #003F46;
  --color-bg-choice: rgba(255, 255, 255, 0.6);
  --color-bg-choice-coverage: rgba(225, 255, 0, 0.6);
  --color-bg-option-selected: rgba(255, 255, 255, 1);
  --color-bg-input: #FFFFFF;
  --color-bg-table: #FFFFFF;
  --color-inverted: #FFFFFF;
  --color-table-label: #002A32;
  --color-border: #B9C5CA;
  --color-border-text-input: #B9C5CA;


  --color-bg-button-active: #E1FF00;
  --color-bg-button: #E1FF00;
  --color-bg-secondary-button: #F1F6F7;
  --color-border-secondary-button: #00686E;
  --color-secondary-button-inverted: #E1FF00;
  --color-bg-secondary-button-inverted: #003F46;

  --color-bg-coverage-bottom: #003F46;
  --color-bg-closing-bottom: #003F46;

  --color-bg-table-container: #FFFFFF;
  --color-border-table: #E8EDF0;
  --color-border-choice-addl-coverage: #00686E;
  --color-coverage-recommended-text: #BDCFB6;
  --color-coverage-recommended-text-table: #F1F6F7;
  --color-coverage-selected-text: #FFFFFF;
  --color-coverage-not-selected-text: #003F46;
  --color-coverage-recommended-border: #003F46;
  --color-coverage-recommended-border-table: #BDCFB6;
  --color-coverage-selected-border: #003F46;
  --color-coverage-not-selected-border: #B9C5CA;
  --color-coverage-recommended-background: #003F46;
  --color-coverage-recommended-background-table: #BDCFB6;
  --color-coverage-selected-background: #003F46;
  --color-coverage-not-selected-background: #FFFFFF;
  --color-coverage-selectable-text: #003F46;

  --color-bg-addtl-coverage-choice-icon: #BDCFB6;

  --color-error: red;


//  Border radius
  --br-input: 50px;
  --br-button: 50px;
  --br-choice-div: 500px;
  --br-choice-div-tall: 20px;
  --br-choice-div-image: 50%;
  --br-dropdown: 10vw;
  --br-table: 50px;

//  MISC
  --width-logo: 100px;
  --phone-icon-width: 40px;

  /* Neutral */
  --color-neutral-0: #FFFFFF;
  --color-neutral-50: #F1F6F7;
  --color-neutral-80: #E8EDF0;
  --color-neutral-100: #ECF5F5;
  --color-neutral-200: #D7E5E5;
  --color-neutral-250: #B9C5CA;
  --color-neutral-300: #7D9298;
  --color-neutral-400: #586E73;
  --color-neutral-500: #3B4D4D;
  --color-neutral-600: #2E3F40;
  --color-neutral-700: #253233;
  --color-neutral-800: #1B2626;
  --color-neutral-900: #090D0D;

  /* Green */
  --color-green-100: #FAFFDC;
  --color-green-150: #F7F9F5;
  --color-green-200: #BDCFB6;
  --color-green-300: #99B48E;
  --color-green-500: #E1FF00;
  --color-green-600: #00686E;
  --color-green-700: #00535A;
  --color-green-800: #003F46;
  --color-green-900: #002A32;

  --color-stepper-text: #002A32;
  --color-stepper-line: #002A32;
  --color-stepper-line-progressed: #002A32;
  --color-stepper-icon: #00686E;
  --color-stepper-active: #00686E;
  --br-stepper-circle: 50%;
}
