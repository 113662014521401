@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./styles/variables";

@font-face {
  font-family: calingo-semibold;
  src: url("assets/fonts/EuclidCircularB-Semibold.otf") format("opentype");
}

@font-face {
  font-family: calingo-regular;
  src: url("assets/fonts/EuclidCircularB-Regular.otf") format("opentype");
}

@font-face {
  font-family: calingo-regularitalic;
  src: url("assets/fonts/EuclidCircularB-RegularItalic.otf") format("opentype");
}

:root {
  scroll-behavior: auto;
}

#main-banner {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-banner);
  padding: 2%;
  color: var(--color-banner);
  position: relative;
  justify-content: space-between;
  align-items: center;
}

#main-banner > * {
  flex-shrink: 0;
  position: relative;
}

#main-header {
  max-width: 600px;
  padding-top: 36px;
  margin-left: auto;
  margin-right: auto;
}

#phone-number-href {
  font-family: calingo-regular;
  color: var(--color-phone-number);
  padding: 0;
  margin: 0;
  line-height: 1.6;
  height: 1px;
}

#phone-icon {
  margin: 0;
  height: auto;
  width: var(--phone-icon-width);
  aspect-ratio: 1 / 1;
  background-image: url("assets/phone_dark.svg");
  background-size: contain;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
}

#calingo-logo {
  width: var(--width-logo);
  height: auto;
  aspect-ratio: 10 / 3;
  background-image: url("assets/logo_calingo_petrol_clipped.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.broker-success {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-sub-page);
  color: var(--color-main-light);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.broker-vertically-split {
  display: flex;
  flex-direction: row;
  justify-items: stretch;
}

.broker-success-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 60px 10%;
  max-width: 60%;
}

.broker-success-left-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 30px;
}

.broker-success-left-content p {
  text-align: center;
  font-family: calingo-semibold;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(12px, 4vw, 32px);
  width: 60%;
}

.broker-success-right {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-self: flex-end;
  flex-grow: 1;
}

.broker-success h3 {
  color: var(--color-main-light);

}

.half-screen-vertical {
  margin: 0;
}

.horizontal-filler-div {
  justify-self: stretch;
  flex-grow: 1;
  background-color: var(--color-main-light);
}

.calingo-logo-success {
  margin-top: 0;
}

.stepper-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  font-family: calingo-regular;
}

.stepper-element-container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.stepper-element {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 5px;
  width: max-content;
}

.update-broker-div {
  max-width: 366px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.invalid-broker-div {
  border: 1px solid var(--color-error);
}

.update-broker-div > .text-input {
  margin: 0;
  padding: 0;
  order: 0;
}

.sub-page-root-home {
  max-width: 366px;
  background-color: var(--color-bg-sub-page);
  margin-left: 20px;
  margin-right: 20px;
}

.sub-page-root-pet {
  max-width: 366px;
  background-color: var(--color-bg-sub-page);
  margin-left: 20px;
  margin-right: 20px;
}

.sub-page-root-closing {
  max-width: 366px;
  background-color: var(--color-bg-sub-page);
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 36px;
}

.sub-page-root-begin-and-pay {
  max-width: 366px;
  background-color: var(--color-bg-sub-page);
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 36px;
}

.sub-page-root-coverage {
  max-width: 1200px;
  padding-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  /*
  padding-left: 15px;
  padding-right: 15px;
  */
}

.bg-container {
  border-radius: 24px;
  background-color: var(--color-bg-table-container);
  padding: 20px 40px 20px;
  max-width: 100vw;

  &:has(.table-wrapper){
    padding-top: 0px;
  }

  //media query for smaller screens
  @media screen and (max-width: 750px) {
    padding: 10px 20px;
  }
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-sub-page);
  justify-content: center;
  align-items: center;
}

.b2c-success {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 1;
}

.b2c-success-top {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.b2c-success-top img {
  margin: 0;
  flex-grow: 1;
}

.b2c-success-bottom {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 72px;
}

.b2c-success-bottom p {
  text-align: center;
  font-family: calingo-semibold;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(12px, 3.5vw, 24px);
  width: 60%;
}

.page-header-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  word-wrap: break-word;
  text-align: center;
}

.page-header-div h3 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  margin: 20px;
  margin-bottom: 0px;
}

.text-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-wrapper-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 30px;
}

.send-offer-link-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.send-offer-in-cc-oneline {
  margin: 0;
  font-size: clamp(10px, 2.5vw, 14px);
  font-family: calingo-regular;
}

.send-offer-in-cc-oneline-inverted {
  margin: 0;
  font-size: clamp(10px, 2.5vw, 14px);
  font-family: calingo-regular;
  color: var(--color-inverted);
}

.two-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.two-buttons-wrapper-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

.two-buttons-wrapper-dynamic-direction {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

@media screen and (max-width: 750px) {
  .two-buttons-wrapper-dynamic-direction {
    flex-direction: row;
    align-items: stretch;
    margin-top: 10px;
  }
}

.two-send-offer-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-wrapper-in-footer {
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding-top: 15px;
}

.button-wrapper-in-footer-no-bottom-margin {
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: center;
  justify-content: center;
}

.oneline-explanation {
  font-size: clamp(10px, 2.5vw, 14px);
  font-family: calingo-regular;
  margin-top: clamp(10px, 2.5vw, 14px);
  color: var(--color-main-dark);
  order: 2;
  text-align: center;
}

.oneline-explanation-left {
  font-size: clamp(10px, 2.5vw, 14px);
  font-family: calingo-regular;
  margin-top: clamp(10px, 2.5vw, 14px);
  color: var(--color-main-dark);
  order: 2;
  text-align: left;
}

.oneline-explanation-inverted {
  font-size: clamp(10px, 2.5vw, 14px);
  font-family: calingo-regular;
  margin-top: clamp(10px, 2.5vw, 14px);
  color: var(--color-inverted);
  order: 2;
  text-align: center;
}

.oneline-explanation-button {
  font-size: clamp(8px, 2vw, 12px);
  font-family: calingo-regular;
  margin-top: clamp(4px, 1.5vw, 8px);
  color: var(--color-main-dark);
  order: 2;
  text-align: center;
}

.send-offer-dropdown-text {
  font-size: clamp(12px, 2.5vw, 20px);
  font-family: calingo-regular;
  color: var(--color-inverted);
  order: 2;
  text-align: center;
  margin-top: 0;
  margin-bottom: 15px;
}

.text-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  height: 39px;
  background: var(--color-bg-input);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.choice-wrapper-tall {
  display: flex;
  flex-direction: row;
  background-color: var(--color-bg-sub-page);
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px
}

.choice-wrapper-short-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 30px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.choice-wrapper-short-3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.choice-wrapper-short-4 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  flex: none;
  order: 1;
  flex-grow: 0;
  row-gap: 0px;
  column-gap: 30px;
}

.bold,
b {
  font-family: calingo-semibold;
}

.center-center-p {
  font-size: clamp(10px, 3.5vw, 15px);
  line-height: 1.6;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.choice-div-short-pet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  width: 105px;
  height: 40px;
  border-radius: var(--br-choice-div);
  flex: none;
  order: 0;
  flex-grow: 0;
  background-color: var(--color-bg-choice);
  border: 1px solid var(--color-border);
  cursor: pointer;

  &.selected {
    background-color: var(--color-coverage-selected-background);
    border: 2px solid var(--color-coverage-selected-border);
    color: var(--color-coverage-selected-text);
  }
}

.choice-div-short-coverage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  max-height: 40px;
  min-width: 80px;
  border-radius: var(--br-choice-div);
  border: 1px solid var(--color-border);
  flex: 1;
  /*order: 0;*/
  /*flex-grow: 0;*/
  background-color: var(--color-bg-choice);
  cursor: pointer;

  &.selected {
    background-color: var(--color-coverage-selected-background);
    border: 2px solid var(--color-coverage-selected-border);
    color: var(--color-coverage-selected-text);
  }
}

.choice-div-short-addtl-coverage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  max-height: 40px;
  border-radius: var(--br-choice-div);
  flex: 1;
  /*order: 0;*/
  /*flex-grow: 0;*/
  background-color: var(--color-coverage-not-selected-background);
  color: var(--color-coverage-not-selected-text);
  border: 2px solid var(--color-coverage-not-selected-border);
  cursor: pointer;


  &.addtl-coverage-selected {
    background-color: var(--color-coverage-selected-background);
    border: 2px solid var(--color-coverage-selected-border);
    color: var(--color-coverage-selected-text);
  }

}

.choice-div-tall {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 170px;
  height: 200px;
  background-color: var(--color-bg-choice);
  border-radius: var(--br-choice-div-tall);
  align-items: center;
  padding: 25px 35px 0;
  gap: 20px;
  border: 1px solid var(--color-border);
  cursor: pointer;
}

.choice-icon-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: var(--color-bg-choice-icon);
  border-radius: var(--br-choice-div-image);
  flex: none;
  flex-grow: 0;
}

.choice-icon-div-img {
  width: 85px;
  height: 85px;
  background-image: url("assets/calingo_icon_cat.svg");
  background-size: contain;
}

.choice-icon-div-img.cat {
  background-image: url("assets/calingo_icon_cat.svg");
}

.choice-icon-div-img.dog {
  background-image: url("assets/calingo_icon_dog.svg");
}

.choice-div-tall-text {
  flex: none;
  order: 1;
  flex-grow: 1;
}

.dropdown {
  border-radius: var(--br-dropdown);
}

.submenu {
  margin-top: 36px;
}

.submenu > label {
  margin-bottom: 20px;
}

.hover-responsive:hover {
  cursor: pointer;
  opacity: 0.6
}

.active-responsive:active {
  opacity: 0.4;
  cursor: pointer;
}

.active-responsive-text-active {
  cursor: pointer;
  border-bottom: 3px solid;
  border-bottom-color: var(--color-underline-stepper);
}

.responsive-text-disabled {
  cursor: default;
  opacity: 0.6;
}

/* remove the blue glow from text input */
.form-control:focus {
  border-color: var(--color-main-light);
  box-shadow: none;
}


#custom-start-date {
  margin-top: 30px;
  padding-bottom: 0px;
}

.infodiv {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  background-color: var(--color-bg-infodiv);
  border-radius: 10px;
  color: var(--color-main-light);
  padding: 20px;
  font-family: calingo-regular;
}

.infodiv-broker-offer-cc {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  background-color: var(--color-bg-infodiv);
  border-radius: 10px;
  color: var(--color-main-light);
  padding: 20px;
  font-family: calingo-regular;
}

.infodiv-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  color: var(--color-header-infodiv)
}

.infodiv-content {
  color: var(--color-content-infodiv);
  font-size: 14px;
  line-height: 1.6;
}

#coverage-info-form {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-wrapper {
  background-color: var(--color-bg-table);
  padding: 0;
  border-radius: var(--br-table);
  border: none;

  &.no-br-top-left {
    border-top-left-radius: 0;
  }

  &.no-br-top-right {
    border-top-right-radius: 0;
  }
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-bg-table);
  width: 100%;
  padding: 0;
  top: 0;

  &.fixed {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.table-header-placeholder {
  width: 100%;
  height: 0;
}

.table-header-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  flex: 1;

  .table-header-element-div {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    padding: 16px 0 24px;
  }

  .recommended-text {
    min-height: 36px;
    color: var(--color-coverage-recommended-text-table);
    display: none;
    text-transform: uppercase;
    font-size: 16px;

    //Media query for smaller screens
    @media screen and (max-width: 750px) {
      font-size: 12px;
    }

    //Media query for smaller screens
    @media screen and (max-width: 450px) {
      font-size: 10px;
    }
  }

  &.recommended {
    border: 2px solid var(--color-coverage-recommended-border-table);
    border-bottom-width: 0;
    border-radius: 30px 30px 0 0;
    padding: 3px 10px 0px;
    margin-top: -38px;

    .recommended-text {
      display: flex;
      justify-content: center;
      align-items: center;

      color: var(--color-coverage-recommended-text-table);
      width: calc(100% + 20px);
      margin: -3px -10px 0 -10px;
      padding: 6px 0;
      background-color: var(--color-coverage-recommended-background-table);
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      margin-bottom: 0px;
    }
  }
}

.table-footer-element label {
  align-self: stretch;
}

.table-header-element label {
  align-self: stretch;
}

.table-header-title {
  font-size: clamp(16px, 3.5vw, 24px);
  line-height: 1.6;
  text-align: center;
  color: var(--color-main-dark);
  margin: 0;
}

.table-header-subtitle {
  font-size: clamp(12px, 2.5vw, 20px);
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  color: var(--color-main-light);
  font-family: calingo-regular;
}

.table-header-text {
  font-size: clamp(10px, 3vw, 16px);
  line-height: 1.6;
  text-align: center;
  color: var(--color-main-light);
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  /* we hide this for now */
  display: none;
  /*
  for mobile
  @media (max-width: 1000px) {
    display: none;
  }
    */
}

.table-pack {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.table-label-div-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg-table-label);
  color: var(--color-table-label);
  min-height: 36px;
  gap: 10px;
}

.infobutton {
  margin: 0;
  font-size: smaller;
  cursor: default;
}

.table-label-text {
  margin: 0;
  font-size: clamp(10px, 2vw, 14px);
}

.table-value-div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--color-main-light);
  min-height: 36px;
}

.table-value-col {
  display: flex;
  flex: 1;
  padding: 10px;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  &.coverage-level-recommended-col{
    border: 2px solid var(--color-coverage-recommended-border-table);
    border-width: 0 2px;
  }
}

.coverage-level-selected-col {
  background-color: var(--color-bg-coverage-level-selected);
}

.table-value-text {
  font-size: clamp(10px, 2vw, 14px);
  line-height: 1.6;
  margin: 0;
  text-align: center;
}

.table-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
}

.table-footer-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  justify-self: stretch;
  width: 100%;
  padding: 10px;
  flex: 1;

  &.coverage-level-recommended-footer{
    border: 2px solid var(--color-coverage-recommended-border-table);
    border-top-width: 0;
    border-radius: 0 0 50px 50px;
  }
}

.coverage-options-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 40px;
  /*margin-bottom: 60px;*/

}

.coverage-options-wrapper label {
  /*
  display: flex;
  align-items: stretch;
  align-self: stretch;
  justify-content: center;

  flex: 0 0 130px;
  */
  width: 135px;
  @media (max-width: 1000px) {
    flex: 1 1 auto;
  }
}

.coverage-option-tall {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: var(--color-bg-coverage-option-tall);
  border-radius: var(--br-choice-div-tall);
  align-items: center;
  align-self: stretch;
  padding: 20px;
  gap: 10px;
  /*border: 1px solid var(--color-border);*/
  border: none;
  /*requiered so that the option ovals all have the same size despite different content*/
  flex: 1;
}

.coverage-option-tall-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: normal;
  align-self: normal;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 40px;
  p {
    margin: 0;
  }
}

.coverage-option-icon-heade-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}

.coverage-option-button-with-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}


.coverage-option-icon-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--color-bg-addtl-coverage-choice-icon);
  border-radius: var(--br-choice-div-image);
  flex: none;
  flex-grow: 0;
}

.coverage-option-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  max-width: 600px;
  /*flex: 1 1 auto;*/
  /*min-width: 85%;*/
  > p {
    margin: 0;
  }
}

.coverage-option-tall .table-header-title {
  /*
  font-size: 18px;
  line-height: 1;
  */
  margin: 0;
  text-align: start;
}

.coverage-option-icon {
  width: 50px;
  height: 50px;
  background-image: url("assets/calingo_icon_dna.svg");
  background-size: contain;
}


.coverage-option-icon.check {
  background-image: url("assets/check.svg");
}


.coverage-option-icon.dna {
  background-image: url("assets/calingo_icon_dna.svg");
}

.coverage-option-icon.global {
  background-image: url("assets/global_coverage.svg");
}

.copayment-excess-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 60px;
}

.copayment-excess-element {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
}

.copayment-excess-element > label, .copayment-excess-element > p {
  margin: 10px;
}

.copayment-explanation {
  max-width: 800px;

  .explanation-container {
    background-color: var(--color-bg-infodiv);
    padding: 40px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-left: 40px;

    &.top-part {
      border-radius: 24px 24px 0 0;

      display: flex;
      flex-direction: column;
      gap: 16px;

      .exp-how-it-works {
        font-family: calingo-regular;
        font-weight: 600;
      }

      .exp-texts {
        color: var(--color-content-infodiv);
        font-size: clamp(12px, 2vw, 20px);
        line-height: 1.2;

        .bold {
          font-weight: 600;
        }
      }

      .exp-calculations {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        font-family: calingo-regular;
        font-weight: 600;

        .calc {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 16px;
          align-items: center;
          font-size: clamp(12px, 2vw, 18px);
          line-height: 1.2;

          .calc-text {
            display: flex;
            align-items: center;
            gap: 10px;

            .title {
              color: var(--color-header-infodiv);
            }

            .hint {
              font-size: clamp(10px, 1.5vw, 14px);
              font-weight: 400;
              /*color: var(--color-content-infodiv);*/
            }
          }

          .calc-value {
            /*color: var(--color-content-infodiv);*/
            text-align: end;
          }
        }
      }

      p {
        margin: 0;
      }
    }

    &.bottom-part {
      padding: 0 40px;
      border-radius: 0 0 24px 24px;
      position: relative;

      .bottom-bordered{
        border-top: 1px dashed var(--color-content-infodiv);

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: clamp(12px, 2vw, 20px);
        line-height: 1.2;
        /*color: var(--color-content-infodiv);*/
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 30px;

        .value{
          /*color: var(--color-content-infodiv);*/
        }
      }

      .cut-out{
        position: absolute;
        top: -12px;
        width: 24px;
        height: 24px;
        background-color: var(--color-bg-table-container);
        border-radius: 50%;

        &.left{
          left: -12px;
        }

        &.right{
          right: -12px;
        }
      }
    }
  }
}

.selectable-list-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.selectable-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;

  img {
    margin: 0
  }

  &.selected {
    .selectable-list-item {
      background-color: var(--color-coverage-selected-background);
      border: 2px solid var(--color-coverage-selected-border);
      color: var(--color-coverage-selected-text);

    }
  }
}

.selectable-list-item {
  background-color: var(--color-coverage-not-selected-background);
  border: 2px solid var(--color-coverage-not-selected-border);
  border-radius: var(--br-button);
  padding: 12px 16px;
  width: 135px;
  height: 40px;
  font-size: clamp(10px, 3.5vw, 15px);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.selectable-recommended {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 2px;

  color: var(--color-coverage-selectable-text);
  border-radius: 25px / 25px;
  background-color: transparent;

  &.recommended {
    /*background-color: var(--color-coverage-recommended-background);*/
  }

  .selectable-recommended-text {
    /*min-height: 24px;*/
    font-size: clamp(12px, 3.5vw, 16px);
    color: var(--color-coverage-recommended-text);
    text-transform: uppercase;
    padding-top: 2px;
    background-color: transparent;
  }
}

.coverage-bottom {
  display: flex;
  background-color: var(--color-bg-coverage-bottom);
  width: 100%;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
}

.coverage-bottom-content {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  max-width: 700px;
  justify-content: space-between;
  align-items: flex-start;
  bottom: 0;
}

@media screen and (max-width: 750px) {
  .coverage-bottom-content {
    flex-direction: column;
    align-items: stretch;
  }
}

.closing-bottom {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-closing-bottom);
  width: 100%;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
}

.closing-bottom-content {
  display: flex;
  column-gap: 30px;
  max-width: 700px;
  justify-content: space-between;
  align-items: baseline;
  bottom: 0;
}

@media screen and (max-width: 750px) {
  .closing-bottom-content {
    flex-direction: column;
    align-items: stretch;
  }
}

.total-amount-div-with-button {
  margin-top: 20px;
  flex: 8;
  min-width: 70%;
}

.total-amount-div-with-button p {
  color: var(--color-inverted);
}

.total-amount-div-with-button h4 {
  color: var(--color-inverted);
  font-size: clamp(12px, 2.5vw, 20px);
  line-height: 1;
  min-height: 30px;
  margin: 0;
  text-align: left;
  font-family: calingo-regular;
}

.total-amount-div-with-button p {
  text-align: left;
}

.total-amount-div-with-button .big-font-span {
  font-size: clamp(22px, 5vw, 30px);
}

.closing-key-value {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.closing-value {
  text-align: end;
}

.text-input-wrapper-asymmetric-2-first-long {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.text-input-wrapper-asymmetric-2-first-long :first-child {
  flex: 8;
}

.text-input-wrapper-asymmetric-2-first-long :nth-child(2) {
  flex: 3;
}

.text-input-wrapper-asymmetric-2-first-short {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.text-input-wrapper-asymmetric-2-first-short :first-child {
  flex: 3;
}

.text-input-wrapper-asymmetric-2-first-short :nth-child(2) {
  flex: 8;
}

.text-input-wrapper-with-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 30px;
}

.choice-with-header {
  padding-bottom: 30px;
}

.top-wrapper {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: baseline;
}

.top-right {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: baseline;
  gap: 5px;
}

.top-left {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;
}

.cta {
  text-align: center;
  margin-top: 24px;
}

app-language-choice {
  z-index: 99;
}

.language-choice-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  position: absolute; /* prevents the expanded language selection part from expanding the containing div and thus moving things around */
  background-color: var(--color-bg-banner);
  z-index: 99;
}

.language-choice-element {
  /*
  padding-left: 10px;
  padding-right: 10px;
  */
  width: 55px;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;

  text-transform: uppercase;

  .language-choice-arrow{
    height: 10px;
    width: 10px;

    background-image: url("assets/arrow-point-to-right.png");
    background-size: contain;

    transition: transform 0.3s;
    transform: rotate(90deg);

    &.expanded{
      transform: rotate(-90deg);
    }
  }
}

.language-choice-element-collapsed {
  cursor: pointer;
  border-left: 1px solid var(--color-bg-banner);
  border-top: 1px solid var(--color-bg-banner);
  border-right: 1px solid var(--color-bg-banner);
  border-bottom: 1px solid var(--color-bg-banner);
}

.language-choice-element-expanded {
  border-left: 1px solid var(--color-banner);
  border-top: 1px solid var(--color-banner);
  border-right: 1px solid var(--color-banner);
}

.red-text {
  color: var(--color-error);
}

input.ng-invalid.submitted-incomplete {
  border: 1px solid var(--color-error);
}

.ng-select.ng-invalid.submitted-incomplete {
  border: 1px solid var(--color-error);
}

.ng-select.custom-coverage-info,
.ng-select.custom-coverage-info .ng-select-container,
.ng-select.custom-pet-info,
.ng-select.custom-pet-info .ng-select-container {
  padding: 0;
  box-shadow: none;
  border-radius: var(--br-button);
}

.ng-select.custom-coverage-info,
.ng-select.custom-coverage-info .ng-select-container {
  min-width: 180px;
}

.ng-select.custom-coverage-info .ng-select-container,
.ng-select.custom-pet-info .ng-select-container {
  border: none;
  box-shadow: none;
}

input,
.ng-select.custom-pet-info .ng-select-container,
.ng-select.custom-coverage-info .ng-select-container,
.ng-option {
  font-family: calingo-regular;
}

.link-in-text,
.link-in-text:hover,
.edit-link,
.edit-link:hover {
  color: rgba(0, 42, 50, 0.8); /*TODO: Parametrize*/
  cursor: pointer;
}


.link-in-footer,
.link-in-footer:hover {
  color: var(--color-inverted);
}

input[type=radio], input[type=checkbox] {
  /*display: none; prevents element from being focused, so we use a small hack here*/
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
}

input[type=radio]:checked + div.choice-div-tall,
input[type=checkbox]:checked + div.coverage-option-tall {
  background-color: var(--color-bg-option-selected);
}

/* when a radio button is untouched (i.e. pristine), for sure nothing is selected.
    Once it's been unprestined, something is for sure selected. */
input[type=radio].ng-pristine + div.submitted-incomplete {
  border: 1px solid var(--color-error);
}

.sibling-selected {
  opacity: 0.4;
}

input[type=date] {
  background-color: var(--color-bg-input);
  /* TODO: figure out how to properly style a date picker */
}

button {
  background-color: var(--color-bg-button);
  color: var(--color-button);
  border: 0;
  border-radius: var(--br-button);
  width: 167px;
  height: 40px;
}

input.text-input {
  border-radius: var(--br-input);
  border: 1px solid var(--color-border-text-input);
}

.secondary-button {
  background-color: var(--color-bg-secondary-button);
  color: var(--color-secondary-button);
  border: 1px solid var(--color-border-secondary-button);
  width: 167px;
  height: 40px;
}

.secondary-button-inverted {
  background-color: var(--color-bg-secondary-button-inverted);
  color: var(--color-secondary-button-inverted);
  border: 1px solid var(--color-secondary-button-inverted);
  width: 167px;
  height: 40px;
}

button:hover {
  /*background-color: var(--color-bg-button-active);*/
  /*color: var(--color-main-light);*/
}

button:active {
  /*background-color: var(--color-bg-button-active);*/
  /*color: var(--color-main-light);*/
}

button:disabled {
  opacity: 0.4
}

body {
  color: var(--color-main-light);
  font-family: calingo-semibold;
}

app-root img {
  max-width: 100%;
  max-height: 100%;
  margin-top: 10%;
}

app-root i {
  font-family: calingo-regularitalic;
}

app-root p, p {
  font-family: calingo-regular;
}

app-root h1 {
  font-size: calc(1.375rem + 1.5vw);
}

app-root h3 {
  margin-top: 60px;

  font-family: calingo-semibold;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(20px, 2.5vw, 24px);
  line-height: 1.6;

  color: var(--color-main-dark);

  /*flex: none;*/
  order: 0;
  flex-grow: 0;
}

.h3-closing-screen {
  margin-top: 40px;
}

app-root h4 {
  margin-top: 36px;
  margin-bottom: 10px;

  font-family: calingo-semibold;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(16px, 2.5vw, 20px);
  line-height: 1.6;

  color: var(--color-main-light);


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

app-root h5 {
  font-family: calingo-semibold;
  font-size: 15px;
  line-height: 1.6;
}

.modal {
  background-color: rgba(0, 42, 50, 0.8);
  display: flex;
}

app-accept-alert,
app-alert {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal-header-custom {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
}

.modal-header-custom > h3 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  height: auto;
  line-height: normal;
}

.modal-content-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px;
  margin: 50px;
  gap: 35px;
  background: var(--color-bg-sub-page);
  border-radius: var(--br-table);
  white-space: pre-line;
  word-wrap: normal;
  max-width: 862px;
}

.modal-footer-custom {
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;

  .cancel {
    background-color: var(--color-bg-secondary-button);
    color: var(--color-secondary-button);
    border: 1px solid var(--color-border-secondary-button);
  }
}

.accept-modal-footer-custom {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}


app-loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  height: 100%;
  width: 100%;
  background: var(--color-bg-loading-screen);
  text-align: center;
}

.loading-screen-header-custom {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.loading-screen-content-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*
  padding-top: clamp(16px,7vw,70px);
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
  */
  padding: 10px;
  margin: 0;
  gap: clamp(15px, 4vh, 35px);
  /*background: var(--color-bg-sub-page);*/
  max-width: 420px;
  border-radius: 0;
  white-space: pre-line;
  word-wrap: normal;
  height: 100%;
  width: 100%;
  color: var(--color-loading-screen);
}

.loading-screen-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: clamp(40%, 6vh, 100%);
  width: auto;
  text-align: center;
  gap: 15px;
}

.loading-screen-image-container > .image {
  max-width: 480px;
  max-height: 480px;
  width: auto;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-size: contain;
  background-image: url("assets/loading_screen_dog_dark.gif");
}

.loading-screen-image-container > .image.dog {
  background-image: url("assets/loading_screen_dog_dark.gif");
}

.loading-screen-image-container > .image.cat {
  background-image: url("assets/loading_screen_cat_dark.gif");
}

.loading-screen-body-custom > h3 {
  margin-bottom: 26px;
  margin-top: 0;
  color: var(--color-loading-screen);
}

/* bootstrap datepicker */
::ng-deep div.ngb-dp-arrow > button {
  color: var(--color-main-dark);
}

mat-slide-toggle {
  background-color: var(--color-main);
}

/* BEGIN SLIDER/SWITCH */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--color-button);
  -webkit-transition: .4s;
  transition: .4s;
}
/*
.slider.round.slider-color-normal:before {
  background-color: red !important;
}

.slider.round.slider-color-inverted:before {
  background-color: blue !important;
}
*/


app-root input:checked + .slider.slider-color-normal {
  background-color: var(--color-bg-button);
}

app-root input:checked + .slider.slider-color-inverted {
  background-color: var(--color-bg-button);
}

app-root input:focus + .slider.slider-color-normal {
  box-shadow: 0 0 1px var(--color-bg-button);
}

app-root input:focus + .slider.slider-color-inverted {
  box-shadow: 0 0 1px var(--color-bg-button);
}

app-root input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* END SLIDER/SWITCH */

.marketing-consent-switch {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
  padding: 10px;
}

.marketing-consent-switch > .switch {
  scale: 0.7;
  flex: none
}

.marketing-consent-explanation {
}

.accept-alert-switch-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.accept-alert-switch-div > p {
  margin: 0;
}

/* COOKIEBOT BANNER */
#CybotCookiebotDialog {
  background-color: var(--color-bg-sub-page) !important;
  font-family: calingo-semibold !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: var(--color-bg-button) !important;
  border-color: var(--color-bg-button) !important;
  color: var(--color-button) !important;
  border-radius: var(--br-button) !important;
  padding: 0 !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid var(--color-main-light) !important;
  color: var(--color-main-light) !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  background-color: var(--color-bg-secondary-button) !important;
  border-color: var(--color-border-secondary-button) !important;
  border-radius: var(--br-button) !important;
  padding: 0 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonDecline {
  background-color: var(--color-bg-secondary-button) !important;
  border-color: var(--color-border-secondary-button) !important;
  border-radius: var(--br-button) !important;
  padding: 0 !important;
}

#CybotCookiebotDialog input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: var(--color-main-light) !important;
}

/*
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a {
  color: var(--color-main-light) !important;
}
*/
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:hover,
#CybotCookiebotDialogNavDeclaration:hover,
#CybotCookiebotDialogNavDetails:hover,
#CybotCookiebotDialogNavAbout:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerNecessary:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerPreference:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerStatistics:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerAdvertising:hover,
#CybotCookiebotDialogDetailBulkConsentLink:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerUnclassified:hover {
  color: var(--color-main-light) !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a {
  color: var(--color-main-light) !important;
}

/* Cookiebot adjust consent */
#CookiebotWidget
#CookiebotWidget-widgetContent {
  background-color: var(--color-bg-sub-page) !important;
  font-family: calingo-semibold !important;
}

#CookiebotWidget
#CookiebotWidget-buttons
#CookiebotWidget-btn-change {
  background-color: var(--color-bg-button) !important;
  border-color: var(--color-bg-button) !important;
  color: var(--color-button) !important;
  border-radius: var(--br-button) !important;
}

#CookiebotWidget-btn-withdraw {
  background-color: var(--color-bg-secondary-button) !important;
  border-color: var(--color-border-secondary-button) !important;
  border-radius: var(--br-button) !important;
}

#CookiebotWidget
.CookiebotWidget-body
.CookiebotWidget-consents-list
li.CookiebotWidget-approved svg {
  fill: var(--color-main-light) !important;
}

#CookiebotWidget .CookiebotWidget-consent-details button {
  color: var(--color-main-light) !important;
}

#CookiebotWidget .CookiebotWidget-logo {
  width: 100%;
  height: 100%;
}
